<template>
  <div :class="$style.root">
    <div :class="$style.label">
      {{ $t('newsletter.prompt') }}
    </div>

    <div :class="$style.content">
      <div :class="$style.inputs">
        <input
          :class="[$style.input, $style.email]"
          placeholder="Email"
          v-model="email"
          type="email"
          autocomplete="off"
          @keyup.enter="subscribe"
        />

        <div :class="$style.inputsColumns">
          <input
            :class="$style.input"
            :placeholder="$t('newsletter.firstName')"
            v-model="firstName"
            autocomplete="off"
            @keyup.enter="subscribe"
          />
          <input
            :class="$style.input"
            :placeholder="$t('newsletter.lastName')"
            v-model="lastName"
            autocomplete="off"
            @keyup.enter="subscribe"
          />
        </div>

        <div
          :class="[$style.input, $style.interests]"
        >
          <div>
            {{$t('newsletter.interestsGroupsLabel')}}:
          </div>

          <div>
            <div
              v-for="interest in interestsData"
              :class="$style.checkboxGroup"
            >
              <input
                type="checkbox"
                :id="interest.id"
                :checked="interest.checked ? '' : null"
                @change="onChangeInterest(interest)"
                :class="$style.checkbox"
              />
              <label :for="interest.id">
                {{ interest.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button
      dir='next'
      :disabled="!isValid ? '' : null"
      @click="subscribe"
      :class="$style.button"
    >
      {{ $t('newsletter.subscribe') }}
    </button>

    <div v-if="res?.success" :class="$style.message">
      {{ $t('newsletter.confirmation') }}
    </div>
    <div v-if="res?.signedUp" :class="$style.message">
      {{ $t('newsletter.signedUp') }}
    </div>
  </div>
</template>

<script setup>
const nuxtApp = useNuxtApp()
const { $i18n } = nuxtApp

import isEmail from 'validator/lib/isEmail'
import isLength from 'validator/lib/isLength'

const email = ref('')
const firstName = ref('')
const lastName = ref('')

const res = ref(null)
watch(res, (val) => {
  if (val) {
    setTimeout(() => {
      res.value = null
    }, 10000)
  }
})

const isValid = computed(() => {
  return isEmail(email.value) && isLength(firstName.value, { min: 1 }) && isLength(lastName.value, { min: 1 })
})

const { data, error } = await useAsyncData(() => {
  return $fetch('/api/mailchimp-get-interest-groups')
})

let interestsData = reactive(data?.value?.interests?.map(interest => {
  return {
    id: interest.id,
    name: interest.name?.toLowerCase() === 'algemeen' ? $i18n.t('newsletter.interests.algemeen') : interest.name,
    checked: interest.name?.toLowerCase() === 'algemeen' ? true : false
  }
})?.sort((a, b) => a.name.localeCompare(b.name)))

const onChangeInterest = (interest) => {
  const checked = interest.checked = !interest.checked
  interestsData.value = interestsData.value?.map(i => {
    if (i.id === interest.id) {
      return { ...i, checked }
    }
    return i
  })
}

const subscribe = async () => {
  if (!isValid.value) return

  const interestIds = toRaw(interestsData)?.filter(i => i.checked)?.map(i => i.id)
  let interestIdsObject = {}
  interestIds.forEach(id => {
    interestIdsObject[id] = true
  })

  const { data, error } = await useFetch('/api/mailchimp', { params: { email: email.value, firstName: firstName.value, lastName: lastName.value, interestIds: interestIdsObject } })
  res.value = data?.value
}
</script>

<style module lang=scss>
.root {
  @media (max-width: $breakpoint-s) {
    font-size: var(--font-default--font-size);
    line-height: var(--font-default--line-height);
  }
}

.content {
  display: flex;
  align-items: center;
  gap: var(--unit--default);
}

.label {
  composes: font-size-medium from global;
  padding-left: .3rem;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: .3rem;
  flex: 1;
}

.inputsColumns {
  display: flex;
  gap: var(--unit--default);
}

.input {
  composes: reset-input from global;
  padding: .3rem;
  width: calc(100% - .6rem);

  border-bottom: var(--stroke--default) solid;

  cursor: default;
}

.input::placeholder {
  color: var(--color--secondary);
}

.input:focus {
  backdrop-filter: var(--backdrop-filter--light);
}

.checkbox {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: white;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: .85em;
  height: .85em;
  border: var(--stroke--default) solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;

  cursor: pointer;
}

.checkbox:checked::before {
  content: "";
  width: 0.45em;
  height: 0.45em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  // transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--color--brown);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

.checkbox:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.interests {
  display: flex;
  flex-wrap: wrap;
  gap: var(--unit--default);
  margin-top: var(--padding--element--top);
}

.checkboxGroup {
  display: flex;
  align-items: center;
  gap: .5ch;
}

.button {
  composes: reset-button button from global;
  margin-top: .5rem;
  padding-left: .3rem;
}

.button[disabled] {
  cursor: not-allowed;
  color: var(--color--gray);
}

.message {
  margin-top: var(--padding--element--top);
}
</style>
